<template>
  <div
    ref="richDiv"
    class="inner-rich-text"
    v-resize="handleResize">
    <!-- <hr style=" height:5px;border:none;border-top:2px dotted #ff3844;" /><br>
    <hr style=" height:5px;border:none;border-top:2px dashed #ff3844;" /><br> -->
    <Toolbar
      v-show="toolShow"
      ref="toolbar"
      style="border-bottom: 1px solid #ccc;"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <!-- <Space
      wrap
      class="text-tool">
      <XIcon iconClass="rich-tag" />
      <XIcon iconClass="rich-location"/>
      <XIcon iconClass="rich-sub"/>
      <XIcon iconClass="rich-annex"/>|
      <XIcon iconClass="rich-size"/>
      <XIcon iconClass="rich-bold"/>
      <XIcon iconClass="rich-italic"/>
      <XIcon iconClass="rich-strikethrough"/>
      <XIcon iconClass="rich-underline"/>
      <XIcon iconClass="rich-font"/>|
      <XIcon iconClass="rich-mark"/>
      <XIcon iconClass="rich-ordered"/>
      <XIcon iconClass="rich-unordered"/>
      <XIcon iconClass="rich-todo"/>
      <XIcon iconClass="rich-quote"/>|
      <XIcon iconClass="rich-left"/>
      <XIcon iconClass="rich-center"/>
      <XIcon iconClass="rich-right"/>
      <XIcon iconClass="rich-increase"/>
      <XIcon iconClass="rich-decrease"/>|
      <XIcon iconClass="rich-link"/>
      <XIcon iconClass="rich-divider"/>
    </Space> -->
    <Space
      wrap
      :size="10"
      class="inner-text-tool"
      @click="editorRef.focus()">
      <Dropdown
        trigger="hover"
        placement="bottom-start">
        <XIcon iconClass="rich-tag" size="18"/>
        <template #list>
          <XTagMenu
            :onSelect="onTagBoxClick"
            :dataList="$store.state.tagList.list"
            :selectedList="currentTask.tagList || []"/>
        </template>
      </Dropdown>
      <Tooltip content="地点" placement="bottom">
        <XIcon iconClass="rich-location" @click="operateOther('address')"  size="18"/>
      </Tooltip>
      <Tooltip v-show="!isToast && currentTask.taskType!=2" content="子任务" placement="bottom">
        <XIcon iconClass="rich-sub" @click="operateOther('subTask')"  size="18"/>
      </Tooltip>
      <!-- <Tooltip content="附件" placement="bottom">
        <XIcon iconClass="rich-annex" @click="operateNode('attachment')"/>
      </Tooltip> -->
      <Poptip
        class="rich-poptip"
        :width="160"
        trigger="hover"
        placement="bottom-start"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="rich-annex" size="18"/>
        <template #content>
          <List :split="false">
            <ListItem v-for="item in attachList" :key="item" class="hand" @click="operateNode(item.icon)">
              <XIcon :iconClass="item.icon" />
              &nbsp;&nbsp;{{ item.title }}
            </ListItem>
          </List>
        </template>
      </Poptip>
      <span class="parting-line" >|</span>
      <Poptip
        :width="220"
        trigger="hover"
        placement="bottom-start"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="rich-size" size="18"/>
        <template #content>
          <div class="space-between">
            <div
              v-for="item, index in fontList" :key="item"
              :style="{marginLeft: index==0 ? '0px' : '8px'}"
              :class="{'size-bg': true, 'select': fontSelect(item.size)}"
              @click="operateMark('fontSize', item.size + 'px')"
            >
              <span :style="{fontSize: item.size + 'px', lineHeight: '20px'}">{{ item.title }}</span>
              <br>
              <span style="fontSize: 12px;">{{ item.desc }}</span>
            </div>
          </div>
        </template>
      </Poptip>
      <Tooltip content="粗体" placement="bottom">
        <XIcon iconClass="bold" @click="operateMark('bold')" :class="menuClass('bold')" size="18"/>
      </Tooltip>
      <Tooltip content="斜体" placement="bottom">
        <XIcon iconClass="italic" @click="operateMark('italic')" :class="menuClass('italic')" size="18"/>
      </Tooltip>
      <Tooltip content="删除线" placement="bottom">
        <XIcon iconClass="strikethrough" @click="operateMark('through')" :class="menuClass('through')" size="18"/>
      </Tooltip>
      <Tooltip content="下划线" placement="bottom">
        <XIcon iconClass="underline" @click="operateMark('underline')" :class="menuClass('underline')" size="18"/>
      </Tooltip>
      <span class="parting-line">|</span>
      <Poptip
        :width="220"
        trigger="hover"
        placement="bottom"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="rich-font" size="18"/>
        <template #content>
          <span style="fontSize: 12px; color: #A6A6A6;">文字颜色</span>
          <Grid :col="6" :border="false" padding="5px 5px">
            <GridItem
              style="text-align: center;"
              v-for="color in textColors"
              :key="color"
            >
              <div
                :class="'color-item' + (currentMark['color'] == color ? ' select' : '')"
                :style="{backgroundColor: color,}"
                @click="operateMark('color', color)"
              ></div>
            </GridItem>
          </Grid>
        </template>
      </Poptip>
      <Poptip
          :width="220"
          trigger="hover"
          placement="bottom"
          @on-popper-show="onPopperShow">
        <XIcon iconClass="mark" size="18"/>
        <template #content>
          <span style="fontSize: 12px; color: #A6A6A6;">背景色</span>
          <Grid :col="6" :border="false" padding="5px 5px">
            <GridItem
                style="text-align: center;"
                v-for="color in backgroundColors"
                :key="color"
            >
              <div
                  :class="'color-item' + (currentMark['bgColor'] == color ? ' select' : '')"
                  :style="{backgroundColor: color,}"
                  @click="operateMark('bgColor', color)"
              ></div>
            </GridItem>
          </Grid>
        </template>
      </Poptip>

     <!-- <Tooltip content="背景色" placement="bottom">
       <XIcon iconClass="" @click="operateMark('bgColor')" :class="menuClass('bgColor')"/>
     </Tooltip> -->
      <span class="parting-line">|</span>
      <!-- <Poptip
        :width="220"
        trigger="hover"
        placement="bottom-start"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="font" />
        <template #content>
          <div>
            <div class="space-between tool-base" style="margin: 5px 0px 10px 0px;">
              <Tooltip content="粗体" placement="bottom">
                <XIcon iconClass="bold" @click="operateMark('bold')" :class="menuClass('bold')" size="30"/>
              </Tooltip>
              <Tooltip content="斜体" placement="bottom">
                <XIcon iconClass="italic" @click="operateMark('italic')" :class="menuClass('italic')" size="30"/>
              </Tooltip>
              <Tooltip content="下划线" placement="bottom">
                <XIcon iconClass="underline" @click="operateMark('underline')" :class="menuClass('underline')" size="30"/>
              </Tooltip>
              <Tooltip content="删除线" placement="bottom">
                <XIcon iconClass="strikethrough" @click="operateMark('through')" :class="menuClass('through')" size="30"/>
              </Tooltip>
              <Tooltip content="背景色" placement="bottom">
                <XIcon iconClass="mark" @click="operateMark('bgColor')" :class="menuClass('bgColor')" size="30"/>
              </Tooltip>
            </div>
            <Divider style="margin: 10px 0;" />
            <div class="space-between">
              <div
                v-for="item, index in fontList" :key="item"
                :style="{marginLeft: index==0 ? '0px' : '8px'}"
                :class="'size-bg' + (currentMark['fontSize'] == item.size + 'px' ? ' select' : '')"
                @click="operateMark('fontSize', item.size + 'px')"
              >
                <span :style="{fontSize: item.size + 'px', lineHeight: '20px'}">{{ item.title }}</span>
                <br>
                <span style="fontSize: 12px; color: #383838;">{{ item.desc }}</span>
              </div>
            </div>
            <Divider style="margin: 10px 0;" />
            <span style="fontSize: 12px; color: #A6A6A6;">文字颜色</span>
            <Grid :col="6" :border="false" padding="5px 5px">
              <GridItem
                style="text-align: center;"
                v-for="color in textColors"
                :key="color"
              >
                <div
                  :class="'color-item' + (currentMark['color'] == color ? ' select' : '')"
                  :style="{backgroundColor: color,}"
                  @click="operateMark('color', color)"
                ></div>
              </GridItem>
            </Grid>
          </div>
        </template>
      </Poptip> -->
      <Poptip
        class="rich-poptip"
        :width="160"
        trigger="hover"
        placement="bottom"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="numlist" size="18"/>
        <template #content>
          <List :split="false">
            <ListItem v-for="item in orderList" :key="item" class="hand" @click="operateNode(item.icon)">
              <XIcon :iconClass="item.icon" />
              &nbsp;&nbsp;{{ item.title }}
            </ListItem>
          </List>
        </template>
      </Poptip>
      <Poptip
        class="rich-poptip"
        :width="160"
        trigger="hover"
        placement="bottom"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="section" size="18"/>
        <template #content>
          <List :split="false">
            <ListItem v-for="item in alignList" :key="item" class="hand" @click="operateNode(item.icon)">
              <XIcon :iconClass="item.icon" />
              &nbsp;&nbsp;{{ item.title }}
            </ListItem>
          </List>
        </template>
      </Poptip>
      <span class="parting-line">|</span>
      <Poptip
        class="rich-poptip"
        :width="160"
        trigger="hover"
        placement="bottom"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="rich-link" size="18"/>
        <template #content>
          <List :split="false">
            <ListItem v-for="item in relatedList" :key="item" class="hand" @click="operateLink(item.icon)">
              <XIcon :iconClass="item.icon" />
              &nbsp;&nbsp;{{ item.title }}
            </ListItem>
          </List>
        </template>
      </Poptip>
      <Poptip
        :width="220"
        trigger="hover"
        placement="bottom-end"
        @on-popper-show="onPopperShow">
        <XIcon iconClass="rich-divider" size="18"/>
        <template #content>
          <div>
            <div class="space-between">
              <div
                class="size-bg"
                v-for="item, index in lineList"
                :key="item"
                :style="{marginLeft: index==0 ? '0px' : '8px'}"
                @click="operateLine(item.icon)">
                <XIcon :iconClass="item.icon" />
                <br class="line-inlineList">
                <span style="lineHeight: 20px">{{ item.title }}</span>
              </div>
            </div>
            <Divider style="margin: 10px 0;" />
            <span style="fontSize: 12px; color: #A6A6A6;">线条颜色</span>
            <Grid :col="6" :border="false" padding="5px 5px">
              <GridItem
                style="text-align: center;"
                v-for="color in textColors"
                :key="color"
                @click="lineColor=color"
              >
                <div
                  class="color-item"
                  :style="{backgroundColor: color, border: lineColor==color?'3px solid #3D87DA':''}"></div>
              </GridItem>
            </Grid>
          </div>
        </template>
      </Poptip>
    </Space>
    <div class="editor-content"  ref="editorWrapper">
      <Editor
        :class="ifocus ? 'ifocus' : ''"
        style="height: 100%;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
        @onChange="handleChanged"
        @onFocus="handleFocus"
        @onBlur="handleBlur"
      />
    </div>
    <!-- <Space :size="16" class="inner-text-tool">
      <template v-for="name in richNames" :key="name">
        <Poptip v-if="name=='sign'" width="240" word-wrap>
          <XIcon :iconClass="name" />
          <template #content>
            <Select
              transfer
              v-model="data.sign"
              filterable
              multiple
              allow-create
              @on-create="onSignCreate"
              placeholder="搜索或创建标签"
            >
              <Option v-for="item in signList" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </template>
        </Poptip>
        <Upload
          action=""
          v-else-if="name=='attachment'"
          :before-upload="beforeUpolad"
          multiple>
          <XIcon :iconClass="name" />
        </Upload>
        <Poptip v-else-if="name=='font'" width="320" trigger="hover" word-wrap>
          <XIcon :iconClass="name" />
          <template #content>
            <div style="height: 300px;">

            </div>
          </template>
        </Poptip>
        <XIcon v-else :iconClass="name" @click="handleRich(name)" />
      </template>
    </Space> -->
    <XLocationModal
      v-model="showMap"
      @onModalOk="onMapModalOk"
    />
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { ref, shallowRef } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { Boot, /* SlateTransforms, */ DomEditor, SlateEditor } from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'

import {
  ossUpload,
  ossViewUrl,
  tagList,
  // taskAdd,
  taskUpdate,
  taskDelete,
  // ossDelete,
  ossHistory,
  userInfo
} from '@/common/api'
import { colorFromValue, colorToValue } from '@/common/util';
import XTagMenu from '@/components/XTagMenu';
import XLocationModal from '@/components/XLocationModal';
import './XDivider/XDivider'
import './XBackgroundColor/XBackgroundColor'

Boot.registerModule(attachmentModule);

/**
  * 任务描述内容，Json格式
  *
  *
  * 每个jsonArray中，type代表描述类型，1为文本描述，11为图片描述，12是音频，13是视频，14是其他文件类型，20是分割线
  *
  * 文本描述中，spanText文字信息，spanList文字样式，quote是否为引用，check清单类型是否选中，textType文本类型，align对齐方式，indent缩进数量（4个空格x数量）
  * textType：0普通文本，2清单文本，3数字序号文本，4列表文本
  * align：0左对齐，1居中对其，2右对齐
  * spanList：start样式开始位置，end样式结束位置，color文字颜色，fontSize文字大小，type：1加粗，2倾斜，3下划线，4删除线，5文字颜色，6添加背景色（黄色），7文字大小
  * spanList: NEW background背景,bold粗体,color文字颜色,content内容,fontSize文字大小,italic斜体,strikethrough删除线,underlined下划线
  *
  * 文件描述中
  * localPath为本地文件的文件名，路径为：固定路径+文件名，因为ios端安卓端的路径不一致，因此本地路径在代码中写死
  * remotePath是阿里云oss中的objectKey,通过objectKey来下载附件并显示
  * imageInfoList是图片文件专有的属性,localPath本地文件名，remotePath阿里云远程objectKey，scale图片缩放程度，transferX图片位移x，transferY图片位移y
  *
  */

const text_colors = [
  '#000000', '#888888', '#CCCCCC', '#8B864E', '#C76114', '#9933FA',
  '#FF0000', '#FF6347', '#FFC0CB', '#228B22', '#00FF00', '#40E0D0',
  '#0000FF', '#5e8ac6', '#3fbcef', '#FF9912', '#FFD700', '#FFFF00'
];

const background_colors = [
  '#FFEB3B', '#CCCCCC', '#FE8B8B', '#00FF00', '#80DAFE', '#FDB75A',
];

const toolbarConfig = {
  toolbarKeys: ['fontSize', 'bold', 'underline', 'italic', 'through', 'color', 'bgColor', 'todo', 'bulletedList', 'numberedList', 'blockquote', 'justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'delIndent', 'insertLink', 'uploadImage', 'uploadVideo', 'uploadAttachment'],
  modalAppendToBody: true
}

export default {
  setup() {
    const editorRef = shallowRef();
    const valueHtml = ref('<p></p>');

    return {
      editorRef,
      valueHtml
    };
  },
  // props: ['modelValue'],
	// emits: ['update:modelValue'],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    isToast: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Editor,
    Toolbar,
    XTagMenu,
    XLocationModal
  },
  created() {
    let that = this;
    this.editorConfig = {
      height:"100%",
      placeholder: '描述',
      autoFocus: false,
      hoverbarKeys: {
        text: {
          menuKeys: [
            "fontSize",
            "|",
            "bold",
            "underline",
            "italic",
            "through",
            "color",
            "bgColor",
            "clearStyle"
          ]
        },
        attachment: {
          menuKeys: ['downloadAttachment'], // “下载附件”菜单
        },
      },
      MENU_CONF: {
        color: {
          colors: text_colors
        },
        bgColor: {
          colors: background_colors
        },
        fontSize: {
          fontSizeList: [
            { name: 'H4(特大号)', value: '22px' },
            { name: 'H3(大号)', value: '18px' },
            { name: 'H2(正文)', value: '15px' },
            { name: 'H1(小号)', value: '11px' },
          ]
        },
        uploadImage: {
          async customUpload(file, insertFn) {
            if (!that.checkFile(file)) return;
            let filename = that.$store.state.user.userId + '/' + Date.now() + file.name;
            ossUpload(filename, file).then( res => {
              if (res) {
                that.ossFileMap[res.url] = filename;
                that.afterOperateFile();
                ossHistory(filename);
                that.addFileList.push(filename);
                insertFn(res.url, res.name, res.url);
              }
            });
          }
        },
        uploadVideo: {
          async customUpload(file, insertFn) {
            if (!that.checkFile(file)) return;
            let filename = that.$store.state.user.userId + '/' + Date.now() + file.name;
            ossUpload(filename, file).then( res => {
              if (res) {
                that.ossFileMap[res.url] = filename;
                that.afterOperateFile();
                ossHistory(filename);
                that.addFileList.push(filename);
                insertFn(res.url);
              }
            });
          }
        },
        uploadAttachment: {
          async customUpload(file, insertFn) {
            if (!that.checkFile(file)) return;
            let filename = that.$store.state.user.userId + '/' + Date.now() + file.name;
            ossUpload(filename, file).then( res => {
              if (res) {
                that.ossFileMap[res.url] = filename;
                that.afterOperateFile();
                ossHistory(filename);
                that.addFileList.push(filename);
                insertFn(file.name, res.url);
              }
            });
          }
        }
      }
    }

    // this.editorRef = shallowRef();
    // this.valueHtml = ref('')
  },
  mounted() {
    let typeIcon = [];
    if ( this.currentTask.taskType!=2){
      console.log("zzz",this.currentTask)
      typeIcon = [{
          title: this.currentTask.taskType==0 ? '转为笔记' : '转为待办',
          icon: 'type'
        }]
    }
    var giveUpTitle = '放弃';
    if (this.currentTask.taskType == 2) {
      giveUpTitle = this.currentTask.endTime == 0 ? '结束习惯' : '重启习惯';
    } else {
      giveUpTitle = this.currentTask.giveUp ? '取消放弃' : '放弃';
    }
    this.moreList = [{
      title: this.currentTask.isTop ? '取消置顶' : '置顶',
      icon: 'top'
    }, {
      title: giveUpTitle,
      icon: 'giveup'
    }, {
      title: '标签',
      icon: 'sign'
    }, {
      title: '添加子任务',
      icon: 'subTask'
    }, {
      title: '添加地址',
      icon: 'address'
    }, {
      title: '上传图片',
      icon: 'image'
    }, {
      title: '上传视频',
      icon: 'video'
    }, {
      title: '上传附件',
      icon: 'attachment'
    }, {
      title: '专注',
      icon: 'focus'
    },
    ...typeIcon
    , {
      title: '删除',
      icon: 'delete'
    }];

    let tags = this.$store.state.tagList.list;
    if (!tags || tags.length == 0) {
      tagList().then(res => {
        if (res) {
          this.$store.commit('updateBaseTags', res);
          let list = this.dgTagTree(null, null, res);
          this.$store.commit('updateTags', list);
        }
      });
    }
  },
  data() {
    return {
      ifocus: false,
      // 阿里云url对应key
      ossFileMap: {},
      toolShow: false,
      // editorRef: null,
      // valueHtml: '',
      mode: 'default',
      toolbarConfig,
      editorConfig: {},
      lineColor: '#000000',

      fontList: [{
        title: 'H1',
        desc: '小号',
        size: 11
      }, {
        title: 'H2',
        desc: '正文',
        size: 15
      }, {
        title: 'H3',
        desc: '大号',
        size: 18
      }, {
        title: 'H4',
        desc: '特大',
        size: 22
      }],
      textColors: text_colors,
      backgroundColors: background_colors,
      orderList: [{
        title: '清单',
        icon: 'list_check',
        key: 'todo'
      }, {
        title: '序号',
        icon: 'list_ordered',
        key: 'bulleted-list'
      }, {
        title: '列表',
        icon: 'list_unordered',
        key: 'numbered-list'
      }, {
        title: '引用',
        icon: 'list_quote',
        key: 'blockquote'
      }],
      alignList: [{
        title: '靠左对齐',
        icon: 'align_left'
      }, {
        title: '居中对齐',
        icon: 'align_center'
      }, {
        title: '靠右对齐',
        icon: 'align_right'
      }, {
        title: '向右缩进',
        icon: 'indent_left'
      }, {
        title: '向左缩进',
        icon: 'indent_right'
      }],
      lineList: [{
        title: '实线',
        icon: 'line_solid'
      }, {
        title: '虚线',
        icon: 'line_dotted'
      }, {
        title: '点线',
        icon: 'line_point'
      }],
      relatedList: [{
        title: '超链接',
        icon: 'related_hyperlink'
      },
      // {
      //   title: '关联任务',
      //   icon: 'related_relevance'
      // }
      ],
      attachList: [{
        title: '图片',
        icon: 'attach_picture'
      }, {
        title: '视频',
        icon: 'attach_video'
      }, {
        title: '其他',
        icon: 'attach_file'
      }],
      moreList: [{
        title: '标签',
        icon: 'sign'
      }, {
        title: '位置',
        icon: 'location'
      }, {
        title: '任务',
        icon: 'subnode'
      }, {
        title: '附件',
        icon: 'attachment'
      }],
      currentMark: {},

      showMap: false,
      richNames: ['sign', 'location', 'subnode', 'attachment', 'font', 'numlist', 'line', 'link', 'section', 'more'],

      data: {
        sign: '',
        location: {},
      },

      signList: [],
      divWidth: 0,

      timeout: null,
      encodeList: [],
      // 添加到阿里云的文件列表
      addFileList: [],
      // 最终富文本里的文件列表
      finalFileList: [],
    }
  },
  computed: {
    currentTask() {
      if (Object.keys(this.task || {}).length > 0) {
        return this.task;
      } else {
        return this.$store.state.currentTask;
      }
    },
    currentProject() {
      return this.$store.state.currentProject;
    },
    projectList() {
      return this.$store.getters.normalProjectList;
    },
    fontTitle() {
      let title = 'H2';
      let fontSize = this.currentMark['fontSize'];
      this.fontList.forEach(element => {
        if (element.size + 'px' == fontSize) {
          title = element.title;
        }
      });
      return title;
    },
  },
  methods: {
    handleBlur() {
      this.ifocus = false
    }, 
    handleFocus() {
      this.ifocus = true
    },
    // 检查上传的附件是否超限
    checkFile(file) {
      let info = this.$store.state.user;
      // 服务保存的是kb，file里是字节
      let check = (info.totalStore - info.usedStore) > (file.size / 1024);
      if (!check) {
        this.$Message.info('存储云空间不足，请升级～');
      }
      return check;
    },
    // 新增或删除附件后更新使用量
    afterOperateFile() {
      userInfo().then(info => {
        if (info && info.userId) {
          this.$store.commit("updateInfo", info);
        }
      });
    },
    dgTagTree(id, name, list) {
      let children = [];
      list.forEach(item => {
        if (item.parentTagIdStr === id) {
          item.parentTagName = name;
          item.list = this.dgTagTree(item.tagIdStr, item.tagName, list);
          children.push(item);
        }
      });
      return children.sort((a, b) => b.positionWeight - a.positionWeight);
    },

    handleResize() {
      this.divWidth = this.$refs.richDiv.offsetWidth;
    },
    showTool() {
      this.toolShow = !this.toolShow;
    },
    addLine(type, color) {
      this.editorRef.focus();
      const node = {
        type: 'divider',
        line: type,
        color,
        children: [{text: ''}]
      };
      this.editorRef.insertNode(node);
    },
    async decodeContent() {
      if (!this.editorRef) {
        return false
      }
      const nodeList = await this.parseSource()
      console.log(this.editorRef)
      this.editorRef.children = nodeList
      console.log(nodeList)
      this.editorRef.updateView()
    },
    // 文本编码
    encodeTextList(textList, urlAddress) {
      let list = [];
      textList.forEach(spanText => {
        if (spanText.type == 'image' || spanText.type == 'video' || spanText.type == 'attachment') {
          this.encodeNode(spanText);
        } else if (spanText.children && spanText.children.length > 0) {
          list = list.concat(this.encodeTextList(spanText.children, spanText.url));
        } else {
          let color = 0;
          if (spanText.color) {
            color = colorToValue(spanText.color);
          }
          let background = !!spanText.bgColor;
          let backgroundColor = background ? colorToValue(spanText.bgColor) : null;
          let fontSize = 0;
          if (spanText.fontSize) {
            fontSize = parseInt(spanText.fontSize.replace('px', ''))
          }
          list.push({
            urlAddress,
            background,
            backgroundColor,
            bold: spanText.bold || false,
            color,
            content: spanText.text,
            fontSize,
            italic: spanText.italic || false,
            strikethrough: spanText.through || false,
            underlined: spanText.underline || false
          })
        }
      });
      return list;
    },
    // 内容编码
    async encodeContent() {
      this.encodeList = [];
      this.finalFileList = [];
      let orderNum = 0
      this.editorRef.children.forEach(node => {
        if (node.type === 'list-item' && node.ordered) {
          node = {...node, orderNum}
          orderNum++;
        } else {
          orderNum = 0;
        }
        this.encodeNode(node);
      });
      this.currentTask.content = JSON.stringify(this.encodeList);
      if (this.currentTask.taskIdStr) {
        await taskUpdate(this.currentTask);
      }
      // 删除阿里云记录
      let deleteList = this.addFileList.filter((file) => {
        return this.finalFileList.indexOf(file) == -1;
      });
      deleteList.forEach((file) => {
        // ossDelete(file);
        ossHistory(file, false);
        this.afterOperateFile();
      });
      this.addFileList = this.finalFileList;
    },
    // 编码node
    encodeNode(node) {
      const alignDic = {
        'left': 0,
        'center': 1,
        'right': 2,
      }
      let indent = 0;
      if (node.indent && node.indent.indexOf('em') > -1) {
        const reg = new RegExp("em", "g");
        indent = parseInt(node.indent.replace(reg, ''));
      }
      if (node.type == 'pre') {
        node.children.forEach(node => {
          this.encodeNode(node);
        });
      } else if (node.type == 'paragraph' || node.type == 'todo' || node.type == 'blockquote' || node.type == 'link' || node.type == 'code') {
        let spanList = this.encodeTextList(node.children, node.url)
        if (spanList.length == 0) return
        let spanText = ''
        spanList.forEach(element => {
          spanText += element.content
        });
        this.encodeList.push({
          language: node.language,
          align: alignDic[node.textAlign] || 0,
          check: node.checked,
          indent,
          number: 0,
          position: 0,
          quote: node.type == 'blockquote',
          spanList,
          spanText,
          textType: node.type == 'todo' ? 2 : 0,
          type: 1
        });
      } else if (node.type == 'list-item') {
        const isnl = node.ordered;
        let spanList = this.encodeTextList(node.children)
        let spanText = ''
        spanList.forEach(element => {
          spanText += element.content
        });
        this.encodeList.push({
          align: alignDic[node.textAlign || 0],
          check: node.checked,
          number: isnl ? node.orderNum+1 : 0,
          position: 0,
          quote: false,
          spanList,
          spanText,
          textType: isnl ? 3 : 4,
          type: 1,
          indent: node.level
        });
      } else if (node.type == 'image') {
        let other = {
          localPath: node.alt,
          scale: 0.5,
          transferX: 0,
          transferY: 0,
          width: 1.0
        }
        other = node.other ? node.other : other;
        if (node?.style?.width) {
          const editorWidth = this.$refs.editorWrapper?.offsetWidth || null
          const imgWidth = node?.style?.width
          if (imgWidth.indexOf('px')> -1 && editorWidth) {
            const scale = parseInt(imgWidth, 10) / editorWidth;
            other = {...other, scale}
          }
          if (imgWidth.indexOf('%')> -1) {
            const scale = (parseFloat(imgWidth) / 100)
            other = {...other, scale}
          }
        }
        this.finalFileList.push(node.alt);
        this.encodeList.push({
          imageInfoList: [{
            remotePath: node.alt,
            ...other
          }],
          type: 11
        });
      } else if (node.type == 'video') {
        let filename = this.ossFileMap[node.src];
        this.finalFileList.push(filename);
        this.encodeList.push({
          localPath: filename,
          remotePath: filename,
          type: 13
        });
      } else if (node.type == 'attachment') {
        let filename = this.ossFileMap[node.link];
        this.finalFileList.push(filename);
        this.encodeList.push({
          localPath: node.fileName,
          remotePath: filename,
          type: 14
        });
      } else if (node.type == 'divider') {
        let map = {
          'solid': 1,
          'dashed': 2,
          'dotted': 3
        }
        this.encodeList.push({
          dividerType: map[node.line] || 1,
          dividerColor: colorToValue(node.color || '#000000'),
          type: 20
        });
      }
    },
    handleCreated(editor) {
      console.log('handleCreated', window.editor)
      if (editor) {
        window.editor = editor;
        this.editorRef =  editor; // 记录 editor 实例，重要！
        console.log(this.editorRef)
        this.decodeContent();
        // console.log(editor.getAllMenuKeys());
      }

    },
    handleChanged() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.encodeContent();
      }, 1000);
    },
    async parseSource() {
      // 对齐方式
      const alignDic = {
        0: 'left',
        1: 'center',
        2: 'right',
      }
      const list = [];
      let content = this.currentTask.content;
      if (!content || content === '[]') {
        return [{
          type: 'paragraph',
          children: [{ text: '' }]
        }];
      }
      let contentList = JSON.parse(content);
      for (let i = 0; i < contentList.length; i++) {
        const item = contentList[i];
        if (item.type == 1) {
          let children = []
          if (item.spanList && item.spanList.length > 0) {
            item.spanList.forEach(span => {
              if (span.urlAddress) {
                children.push({
                  type: 'link',
                  url: span.urlAddress,
                  children: [{ text: span.content}]
                });
              } else {
                var bgColor = null;
                if (span.background) {
                  bgColor = span.backgroundColor == 0 ? '#FFC300' : colorFromValue(span.backgroundColor);
                }
                children.push({
                  bgColor: bgColor,
                  bold: span.bold,
                  color: (!span.color || span.color == 0) ? null : colorFromValue(span.color),
                  text: span.content,
                  fontSize: this.sizeToFont(span.fontSize),
                  italic: span.italic,
                  through: span.strikethrough,
                  underline: span.underlined,
                  urlAddress: span.urlAddress
                })
              }
            });
          } else {
            children = [{
              text: '',
              fontSize: null
            }];
          }
          let obj = {
            type: 'paragraph',
            textAlign: alignDic[item.align],
            indent: item.indent + 'em',
            checked: item.check,
            children: children
          }

          if (item.textType == 0) {
            if (item.language != undefined && item.language != null) {
              obj.type = 'code'
              obj.language = item.language
              list.push({
                type: 'pre',
                children: [obj]
              })
            } else {
              if (item.quote) {
                obj.type = 'blockquote'
              }
              list.push(obj);
            }
          } else if (item.textType == 2) {
            obj.type = 'todo';
            list.push(obj);
          } else if (item.textType == 3 || item.textType == 4) {
              list.push({
                type:'list-item',
                level: item.indent,
                // indent: item.indent + 'em',
                checked: item.check,
                textAlign: alignDic[item.align],
                ordered: (item.textType == 3),
                children: obj.children
              });
          }
        } else if (item.type == 11) {
          const editorWidth = this.$refs.editorWrapper.offsetWidth
          let images = item.imageInfoList;
          let subChildren = [];
          for (let j = 0; j < images.length; j++) {
            const item = images[j];
            if (!item.remotePath) continue;
            const url = await ossViewUrl(item.remotePath);
            this.addFileList.push(item.remotePath);
            subChildren.push({
              type: 'image',
              alt: item.remotePath,
              src: url,
              href: url,
              other: item,
              children: [{ text: '' }],
              style:{
                width: editorWidth * item.scale + 'px'
              }
            });
          }
          list.push({
            type: 'paragraph',
            children: subChildren
          });
        } else if (item.type == 12 || item.type == 13) {
          if (!item.remotePath) continue;
          const url = await ossViewUrl(item.remotePath);
          this.ossFileMap[url] = item.remotePath;
          this.addFileList.push(item.remotePath);
          list.push({
            type: 'paragraph',
            children: [{
              type: 'video',
              alt: item.remotePath,
              src: url,
              localPath: item.localPath,
              width: this.divWidth - 30,
              children: [{ text: '' }]
            }]
          })
        } else if (item.type == 14) {
          if (!item.remotePath) continue;
          const url = await ossViewUrl(item.remotePath);
          this.ossFileMap[url] = item.remotePath;
          this.addFileList.push(item.remotePath);
          list.push({
            type: 'paragraph',
            children: [ {
              type: 'attachment',
              fileName: item.localPath,
              link: url,
              children: [{ text: '' }]
            }]
          })
        } else if (item.type == 20) {
          let map = {
            1: 'solid',
            2: 'dashed',
            3: 'dotted'
          }
          list.push({
            type: 'divider',
            line: map[item.dividerType] || 'solid',
            color: item.dividerColor ? colorFromValue(item.dividerColor) : '#000',
            children: [{text: ''}]
          })
        }
      }
      return list;
    },
    /// 字体数字转字体 兼容老版本
    sizeToFont(size) {
      if (!size) return null
      if (size == 11 || size == 15 || size == 18 || size == 22) {
        return size + 'px'
      } else if (size <= 22) {
        return '15px'
      } else if (size <= 41) {
        return '11px'
      } else if (size <= 60) {
        return '15px'
      } else if (size <= 72) {
        return '18px'
      } else {
        return '22px'
      }
    },
    operateOther(type) {
      if (this.isToast) {
        this.showMap = true;
        return;
      }
      this.$store.commit('updateListFlag', { key: type });
    },
    handleRich(name) {
      // const toolbar = DomEditor.getToolbar(this.editorRef)
      // // console.log(toolbar.getConfig().toolbarKeys);
      // console.log(toolbar);
      // console.log(toolbar.toolbarItems[2].$button[0].click);
      // // console.log(toolbar.menus.divider);
      // console.log(this.$refs.toolbar);

      if (name == 'sign') {
        console.log(name);
      } else if (name == 'location') {
        this.showMap = true;
      } else if (name == 'subnode') {
        let node = { type: 'todo', children: [{ text: '' }] };
        this.editorRef.insertNode(node)
      } else if (name == 'attachment') {
        console.log(name);
      } else if (name == 'font') {
        let node = { type: 'paragraph', children: [{ text: 'aaa' }] };
        this.editorRef.insertNode(node)
      } else if (name == 'numlist') {
        // if (this.editorRef.children.last)
        // console.log(this.editorRef.value.children);
        let node = { type: 'numbered-list', children: [{
          type: 'list-item',
          children: [{ text: 'aaa' }]
        }] };
        this.editorRef.insertNode(node)
      } else if (name == 'line') {
        let node = { type: 'divider', children: [{ text: 'aaa' }] };
        this.editorRef.insertNode(node)
      } else if (name == 'link') {
        let node = { type: 'paragraph', children: [{ text: 'aaa' }] };
        this.editorRef.insertNode(node)
      } else if (name == 'section') {
        let node = { type: 'divider', children: [{ text: 'aaa' }] };
        this.editorRef.insertNode(node)
      } else if (name == 'more') {
        let node = { type: 'paragraph', children: [{ text: 'aaa' }] };
        this.editorRef.insertNode(node)
      }
    },
    onMapSelected(value) {
      this.data.location = value;
    },
    onSignCreate(val) {
      this.signList.push(val);
    },
    beforeUpolad(file) {
      ossUpload(file.name, file).then(res => {
        if (res) {
          let node = { type: 'image', alt: '3.gif', src: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg2.niutuku.com%2Fdesk%2F1208%2F1300%2Fntk-1300-31979.jpg&refer=http%3A%2F%2Fimg2.niutuku.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1668432582&t=9509eaa05427a33f2a4b4eabbe0c2137', children: [{ text: '' }] };
          this.editorRef.insertNode(node);
        }
      });
      return false;
    },
    onProjectTreeSelect(list) {
      if (list && list.length > 0) {
        let node = list[0];
        if (this.currentTaskId.projectIdStr != node.projectIdStr) {
          this.currentTask.projectIdStr = node.projectIdStr;
          taskUpdate(this.currentTask).then(() => {
            this.$store.replace('/manager/work/' + this.currentProject.projectIdStr);
          });
        }
      }
    },
    onProjectClick() {
      // const w = document.getElementsByClassName('w-e-textarea-divider');
      // console.log(w);
      // if (w.length > 0) {
      //   const d = w[0]
      //   d.setAttribute('style', 'background-color:#FF0000;height: 100px;');
      // }
      // this.editorRef.focus();

      // const toolbar = DomEditor.getToolbar(this.editorRef);
      // console.log(toolbar);

      // this.editorRef.addMark('bold', true)

      // console.log(toolbar.toolbarItems[2]);
      // console.log(toolbar.toolbarItems[2].$button.css);
      // toolbar.toolbarItems[2].$button.click();
      // toolbar.toolbarItems[2].setActive();

      // toolbar.toolbarItems[2].$button[0].click();

      // console.log(toolbar.toolbarItems[2].menu.isActive(this.editorRef));
    },
    // 操作mark
    operateMark(key, value = true) {
      this.editorRef.focus();
      if (['color', 'fontSize','bgColor'].indexOf(key) == -1) {
        if (this.currentMark && this.currentMark[key]) {
          value = null;
        }
      }
      this.currentMark[key] = value;
      for (const inKey in this.currentMark) {
        if (Object.hasOwnProperty.call(this.currentMark, inKey)) {
          const element = this.currentMark[inKey];
          this.editorRef.addMark(inKey, element);
        }
      }
    },
    operateNode(key) {
      const toolbar = DomEditor.getToolbar(this.editorRef);
      const curToolbarConfig = toolbar.getConfig()

      this.editorRef.focus();
      let keyObj = {
        'list_check': 'todo',
        'list_unordered': 'bulletedList',
        'list_ordered': 'numberedList',
        'list_quote': 'blockquote',
        'align_left': 'justifyLeft',
        'align_center': 'justifyCenter',
        'align_right': 'justifyRight',
        'indent_left': 'indent',
        'indent_right': 'delIndent',
        'related_hyperlink': 'insertLink',
        'divider': 'divider',
        'image': 'uploadImage',
        'video': 'uploadVideo',
        'attach_picture': 'uploadImage',
        'attach_video': 'uploadVideo',
        'attach_file': 'uploadAttachment',        
      }
      let editorKey = keyObj[key];
      let index = curToolbarConfig.toolbarKeys.indexOf(editorKey);
      toolbar.toolbarItems[index].$button.click();
    },
    operateLine(type) {
      this.editorRef.focus();
      let map = {
        'line_solid': 'solid',
        'line_dotted': 'dashed',
        'line_point': 'dotted'
      }
      type = map[type] || 'solid';
      const node = {
        type: 'divider',
        line: type,
        color: this.lineColor,
        children: [{text: ''}]
      };
      this.editorRef.insertNode(node);
    },
    operateLink(key) {
      if (key == 'related_hyperlink') {
        this.operateNode(key);
      } else if (key == 'related_relevance') {
        console.log(key);
      }
    },
    operateMore(key) {
      if (key == 'image' || key == 'video' || key == 'attachment') {
        this.operateNode(key);
      } else if (key == 'top') {
        this.currentTask.isTop = !this.currentTask.isTop;
        this.updateList();
      } else if (key == 'giveup') {
        if (this.currentTask.taskType == 2) {
          this.currentTask.endTime = this.currentTask.endTime == 0 ? Date.now() : 0;
        } else {
          if (this.currentTask.giveUp) {
            this.currentTask.giveUp = false;
            this.currentTask.completeTime = 0;
          } else {
            this.currentTask.giveUp = true;
            this.currentTask.completeTime = Date.now();
            if (!this.currentTask.originTime) {
              this.currentTask.originTime = this.currentTask.startTime;
            }
          }
        }
        this.updateList();
      } else if (key == 'sign') {
        console.log(key);
      } else if (key == 'subTask') {
        console.log(key);
      } else if (key == 'address') {
        this.showMap = true;
      } else if (key == 'focus') {
        console.log('');
      } else if (key == 'type') {
        this.currentTask.taskType = this.currentTask.taskType == 0 ? 0 : 1;
        this.updateList();
      } else if (key == 'delete') {
        taskDelete(this.currentTask.taskIdStr).then(res => {
          if (res) {
            this.$router.replace('/manager/work/' + this.$route.params.project);
          }
        });
      }
    },
    updateList() {
      this.$store.commit('updateListFlag', { key: 'update' });
    },
    onPopperShow() {
      this.editorRef.focus();
      let mark = SlateEditor.marks(this.editorRef);
      this.currentMark = mark;
    },
    menuClass(key) {
      if (this.currentMark[key]) {
        return 'icon-select';
      }
      return '';
      // let className = 'icon-bg ';
      // if (this.currentMark[key]) {
      //   className += 'high';
      // }
      // return className;
    },
    fontSelect(size) {
      let fontSize = this.currentMark['fontSize'];
      return (!fontSize && size == 15) || fontSize == size + 'px';
    },
    onMapModalOk(location) {
      if (location) {
        this.editData.addressIdStr = location.addressIdStr;
      } else {
        this.editData.addressIdStr = null;
      }
      if (this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask);
      }
    },
    onTagBoxClick(tag) {
      if (this.currentTask.tagList && this.currentTask.tagList.indexOf(tag.tagIdStr) > -1) {
        let index = this.currentTask.tagList.indexOf(tag.tagIdStr);
        this.currentTask.tagList.splice(index, 1);
      } else {
        let list = this.currentTask.tagList || [];
        list.push(tag.tagIdStr);
        this.currentTask.tagList = list;
      }
      if (this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask);
      }
    }
  },
  watch: {
    currentTask() {
      if (this.currentTask.taskIdStr) {
        setTimeout(() => {
          console.log('currentTask')
          this.decodeContent();
        }, 0);
        
      }
      this.addFileList = [];
    }
  },
  beforeUnmount() {
    const editor = this.editorRef
    if (editor == null) return
    editor.destroy()
  }
}
</script>

<style lang="less" scoped>
  .inner-rich-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-height: 120px;
    overflow: auto;
    .svg-icon {
      &:hover{
        transform: scale(1.3)
      }
    }
    .rich-poptip{
      :deep(.ivu-poptip-body-content) {
        padding: 0;
      }
    }
    :deep(ol li) {
      list-style: decimal outside none;
    }
    :deep(ul li) {
      list-style: disc outside none;
      padding: 7px 12px;
      color: var(--vp-c-text-2);
      &:hover{
        color: var(--vp-c-text-1);
        background: var(--vp-c-bg-alt);
        .ivu-typography{
          color: var(--vp-c-text-1);
        }
        .svg-icon{
           transform: scale(1.3)   
        }
      }
    }
    :deep(.w-e-select-list ul li) {
      padding: 7px 0 7px 25px;
    }
    :deep(.w-e-drop-panel ul li){
      padding: 2px;
    }
    :deep(.ivu-select-dropdown li) {
      list-style: none;
    }
    :deep(.w-e-hover-bar),
    :deep(.w-e-drop-panel) {
      border-radius: 8px;
    }
    :deep(.w-e-hover-bar){
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 1px 5px 10px 1px rgba(0, 0, 0, 0.09);
    }
    .ivu-grid-item{
      &:hover{
        transform: scale(1.3)
      }
    }
    :deep(.ivu-poptip-body){
      padding: 0;
    }
    :deep(.ivu-poptip-body-content) {
      padding: 10px;
    }
    .hand {
      cursor: pointer;
      border-radius: 4px;
      color: var(--vp-c-text-2);
      &:hover {
        background: var(--vp-c-bg-alt);
      }
    }
    .inner-text-tool {
      // border-top: 1px solid var(--vp-c-border);
      // border-bottom: 1px solid var(--vp-c-border);
      padding: 4px 10px 4px 20px;
      width: 100%;
      // background-color: #FAFAFA;
      display: flex;
      align-items: center;
      .icon-select {
        // background-color: #F0F0F0;
      }
      .parting-line{
        color: var(--vp-c-text-3);
      }
      .tool-base {
        span {
          font-weight: bold;
          cursor: pointer;
        }
        .icon-bg {
          padding: 5px;
          border-radius: 4px;
          font-size: 30px;
          &.high {
            background-color: #e8e8e8
          }
        }
      }
      .size-bg {
        padding: 6px;
        border: 1px solid var(--vp-c-divider);
        background-color: transparent;
        color:var(--vp-c-text-2);
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        &.select {
          border-color: var(--vp-c-text-1);
          background-color: var(--vp-c-bg-alt);
          color:var(--vp-c-text-1);
          .svg-icon{
            transform: scale(1.3)   
          }
        }
        &:hover {
          border-color: var(--vp-c-text-1);
          background-color: transparent;
          color:var(--vp-c-text-1);
          .svg-icon{
            transform: scale(1.3)   
          }
        }
        &.select:hover {
          border-color: var(--vp-c-text-1);
          color:var(--vp-c-text-1);
          .svg-icon{
            transform: scale(1.3)   
          }
        }
      }
      .color-item {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        cursor: pointer;
        &.select {
          border: 3px solid #e8e8e8;
          transform: scale(1.3);
        }
      }
      .fixed:deep(.ivu-poptip-body-content) {
        height: 200px;
      }
    }
  }
  .editor-content {
    flex:1;
    height: 100%;
    overflow-y: scroll;
    :deep(img) {
      border-radius: 6px;
    }
    :deep(.w-e-image-container ){
      border-radius: 6px;
    }
    .ifocus {
      :deep(.w-e-text-container){
        color: var(--vp-c-text-1);
        border-color: #1b9aee;
        background-color: var(--vp-c-bg-alt);
      }
    }
    :deep(.w-e-bar-item){
      border-radius: 4px;
    }
    :deep(.w-e-text-container){
      border-radius: 8px;
      border: 1px dashed var(--vp-c-bg-node);
      background-color: var(--vp-c-bg-soft);
      margin-left: 5px;
      padding: 0 5px;
      transition: all .2s linear;
      &:hover{
        border-color: #1b9aee;
      }
    }
  }
  :deep(.w-e-modal){
    border-radius: 6px;
  }
</style>

